import request from '../request';

const serverName = '/iot-kcloud/kolb/statistics';

/** @name 烘焙统计 */
export function bakingStatistics(data) {
  return request(`${serverName}/bakingStatistics`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 获取企业下所有的软件版本信息
 */
export function listByTenant() {
  return request(`${serverName}/listByTenant`, {
    method: 'GET',
  });
}
/**
 * @name 根据软件版本获取设备列表
 */
export function equipmentBySoftwareIds(data) {
  return request(`${serverName}/equipmentBySoftwareIds`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 获取设备绑定用户集合
 */
export function bindByEquipmentIds(data) {
  return request(`${serverName}/bindByEquipmentIds`, {
    method: 'POST',
    body: data,
  });
}
