<template>
  <div :class="$style.container">
    <div :class="[$style.title, $style.common]">
      烘焙次数/使用设备
    </div>
    <no-data-chart
      font="120px"
      v-if="isNoData"
      isIcon
      :style="{ height: '320px', width: '100%' }"
    />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';

const option = data => {
  // const bodyCss = getComputedStyle(document.body);
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    grid: {
      left: '4%',
      right: '5%',
      bottom: '15%',
      top: '25%',
    },
    legend: {
      bottom: 0,
      data: ['times', 'devices'],
    },
    xAxis: [
      {
        type: 'category',
        data: data.xAxis,
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'times',
        interval: 50,
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        name: 'devices',
        interval: 5,
        axisLabel: {
          formatter: '{value} 台',
        },
      },
    ],
    series: [
      {
        name: 'times',
        type: 'bar',
        tooltip: {
          valueFormatter: function(value) {
            return value;
          },
        },
        data: data.times,
      },
      {
        name: 'devices',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function(value) {
            return value;
          },
        },
        data: data.devices,
      },
    ],
    color: [
      '#ed5a65',
      '#fc7930',
      '#ffb23b',
      '#ec2d7a',
      '#ec9bad',
      '#eed045',
      '#69a794',
      '#bacf65',
      '#813c85',
      '#add5a2',
      '#363433',
    ],
  };
};
@Component()
export default class UseDeviceChart extends Vue {
  @Prop({ type: String, default: 'useDeviceEatChart' }) id;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: String, default: '361px' }) height;
  get isNoData() {
    if (!this.data.xAxis || (this.data.xAxis && !this.data.xAxis.length)) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    this.chart = echarts.init(document.getElementById(this.id));
    this.chart.setOption(option(this.data));
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;

  .common {
    position: absolute;
    height: 54px;
    line-height: 54px;
    z-index: 1;
    display: inline-block;
  }
  .title {
    font-size: 14px;
    text-align: left;
    left: 0;
    top: 0;
    color: var(--font);
  }
}
.chartBox {
  width: 100%;
}
</style>
