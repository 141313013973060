<template>
  <layout-content>
    <template v-slot:header-left>
      <span :class="$style.title">报表统计</span>
    </template>
    <div :class="$style.content" slot="content">
      <section :class="$style.formPanel">
        <a-form-model ref="ruleForm" layout="inline">
          <a-form-model-item>
            <a-range-picker v-model="form.date"></a-range-picker>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="form.product"
              placeholder="全部产品"
              allow-clear
              @change="changeProduct"
            >
              <a-select-option
                v-for="item in productList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="form.deviceCode"
              placeholder="全部设备编号"
              allow-clear
              @change="changeDevice"
            >
              <a-select-option
                v-for="item in deviceList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="form.user"
              placeholder="全部用户"
              allow-clear
            >
              <a-select-option
                v-for="item in userList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="form.mode"
              placeholder="全部模式"
              allow-clear
            >
              <a-select-option
                v-for="item in modeList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="getData" :class="$style.mr20"
              >查询</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </section>
      <section :class="$style.chartCtx">
        <section :class="$style.chartBox">
          <section :class="$style.card">
            <TopChart :data="topData" />
          </section>
          <section :class="$style.card">
            <UseDeviceChart :data="useDevice" />
          </section>
        </section>
        <section :class="$style.countCard">
          <AvgChart :data="avg" />
        </section>
      </section>
    </div>
  </layout-content>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import TopChart from './components/top-chart.vue';
import UseDeviceChart from './components/use-device-chart.vue';
import AvgChart from './components/avg-chart.vue';
import {
  bakingStatistics,
  listByTenant,
  equipmentBySoftwareIds,
  bindByEquipmentIds,
} from '@/services/oven/statistics';

@Component({
  components: {
    LayoutContent,
    TopChart,
    UseDeviceChart,
    AvgChart,
  },
})
export default class Index extends Vue {
  form = {
    date: [],
    product: undefined,
    user: undefined,
    mode: undefined,
    deviceCode: undefined,
  };

  reset() {
    this.form = {
      date: [],
      product: undefined,
      user: undefined,
      mode: undefined,
      deviceCode: undefined,
    };
    this.getData();
  }

  productList = [];
  userList = [];
  modeList = [
    {
      id: '-1',
      label: '全部模式',
    },
    {
      id: 'MANUAL',
      label: '手动模式',
    },
    {
      id: 'AUTO',
      label: '自动模式',
    },
  ];
  deviceList = [];

  list = [];
  topData = [];
  useDevice = {
    xAxis: [],
    times: [],
    devices: [],
  };
  avg = {
    xAxis: [],
    totalTime: [],
    avgTime: [],
  };
  async getData() {
    const params = {};
    if (this.form.date && this.form.date.length > 1) {
      params['startTime'] = this.form.date[0].startOf('day').format('x');
      params['endTime'] = this.form.date[1].endOf('day').format('x');
    }
    if (this.form.product && this.form.product !== '-1') {
      params['productId'] = this.form.product;
    }
    if (this.form.deviceCode && this.form.deviceCode !== '-1') {
      params['equipmentIds'] = [this.form.deviceCode];
    }
    if (this.form.user && this.form.user !== '-1') {
      params['bindUserIds'] = [this.form.user];
    }
    if (this.form.mode && this.form.mode !== '-1') {
      params['operationMode'] = this.form.mode;
    }
    const {
      bakingDetailVOList,
      orderBaking,
      totalBaking,
    } = await bakingStatistics(params);
    this.list = bakingDetailVOList.records;
    let array = [];
    let useDevice = {
      xAxis: [],
      times: [],
      devices: [],
    };
    let avg = {
      xAxis: [],
      totalTime: [],
      avgTime: [],
    };
    this.list.forEach(item => {
      useDevice.xAxis.push(item.menuName);
      useDevice.times.push(item.bakingCount);
      useDevice.devices.push(item.bakingEquipmentCount);
      avg.xAxis.push(item.menuName);
      avg.totalTime.push(item.totalBakingTime);
      avg.avgTime.push(item.avgBakingTime);
      array.push({
        value: +((item.bakingCount / totalBaking) * 100).toFixed(2),
        name: item.menuName,
        num: item.bakingCount,
      });
    });
    array.push({
      value: +((orderBaking / totalBaking) * 100).toFixed(2),
      name: '其他',
      num: orderBaking,
    });
    this.topData = array;
    this.avg = avg;
    this.useDevice = useDevice;
  }

  async getProduct() {
    const list = await listByTenant();
    const array = [
      {
        id: '-1',
        label: '全部产品',
      },
    ];
    list.forEach(child => {
      array.push({
        id: child.pkId,
        label: child.productType,
      });
    });
    this.productList = array;
  }

  async getDevice(params) {
    const array = [
      {
        id: '-1',
        label: '全部设备编号',
      },
    ];
    const list = await equipmentBySoftwareIds([params]);
    list.forEach(child => {
      array.push({
        id: child.pkId,
        label: child.equipmentNumber,
      });
    });
    this.deviceList = array;
  }
  async getUser(params) {
    const array = [
      {
        id: '-1',
        label: '全部用户',
      },
    ];
    const list = await bindByEquipmentIds([params]);
    list.forEach(child => {
      array.push({
        id: child.pkId,
        label: child.memberName,
      });
    });
    this.userList = array;
  }
  async changeProduct(e) {
    this.getDevice(e);
  }
  async changeDevice(e) {
    this.getUser(e);
  }
  mounted() {
    this.getProduct();
    this.getData();
  }
}
</script>
<style lang="less" module>
.title {
  font-size: 16px;
}
.content {
  padding: 20px 28px;
}
.chartCtx {
  margin-top: 10px;
  padding: 5px;
  .chartBox {
    display: flex;
  }
  .card + .card {
    margin-left: 20px;
  }
  .card,
  .countCard {
    height: 361px;
    box-shadow: 0px 0px 10px 0px var(--shadow);
    border-radius: 10px;
    flex: 1;
    padding: 0 20px;
  }
  .countCard {
    margin-top: 20px;
  }
}
.mr20 {
  margin-right: 15px;
}
</style>
