<template>
  <div :class="$style.container">
    <div :class="[$style.title, $style.common]">
      Top10食谱
    </div>
    <no-data-chart
      font="120px"
      v-if="isNoData"
      isIcon
      :style="{ height: '320px', width: '100%' }"
    />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';

const option = data => {
  // const bodyCss = getComputedStyle(document.body);
  return {
    grid: {
      bottom: 600,
    },
    tooltip: {
      trigger: 'item',
      formatter: params => {
        return `<span style="border-radius:50%; border:4px solid ${params.color}; display:inline-block;margin-right:4px;"></span>${params.data.name}: ${params.data.num} ${params.data.value}%`;
      },
    },
    legend: {
      type: 'scroll',
      bottom: '1%',
      icon: 'circle',
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          formatter: '{c0}%',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: true,
        },
        data,
      },
    ],
    color: [
      '#ffb23b',
      '#ec2d7a',
      '#ec9bad',
      '#eed045',
      '#ed5a65',
      '#69a794',
      '#bacf65',
      '#813c85',
      '#fc7930',
      '#add5a2',
      '#363433',
    ],
  };
};
@Component()
export default class TopChart extends Vue {
  @Prop({ type: String, default: 'topEatChart10' }) id;
  @Prop({ type: Array, default: () => [] }) data;
  @Prop({ type: String, default: '361px' }) height;

  get isNoData() {
    if (!this.data || (this.data && !this.data.length)) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }

  initChart() {
    this.chart = echarts.init(document.getElementById(this.id));
    this.chart.setOption(option(this.data));
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;

  .common {
    position: absolute;
    height: 54px;
    line-height: 54px;
    z-index: 1;
    display: inline-block;
  }
  .title {
    font-size: 14px;
    text-align: left;
    left: 0;
    top: 0;
    color: var(--font);
  }
}
.chartBox {
  width: 100%;
}
</style>
